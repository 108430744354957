/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Halls from 'components/Halls'
import MoreInformation from 'components/MoreInformation'
import CallToAction from 'components/CallToAction'

// Elements
import ContentDefault from 'components/elements/ContentDefault'
import HeroDefault from 'components/elements/HeroDefault'
import MoodImage from 'components/elements/MoodImage'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        contactRelation: contact_relation
        ctaRelation: cta_relation
        moodImage: view_mood_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      title, content, path, yoastMeta, acf: { backgroundImage, moodImage, contactRelation, ctaRelation }
    }
  }
}) => {
  return (
    <Layout headerTop>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={backgroundImage.localFile.childImageSharp.meta.src} article />
      <HeroDefault
        title={title}
        image={backgroundImage}
      />
      <section>
        <div className="container">
          <div className="row pb-5 ">
            {!ctaRelation ? (
              <div className="col-lg-12 pr-lg-5 py-5">
                <TitleSub>{parse(title)}</TitleSub>
                <BrandTitle />
                <ContentDefault className="pr-lg-4 mt-5" content={content} />
              </div>
            ) : (
              <>
                <div className="col-lg-8 pr-lg-5 py-5">
                  <TitleSub>{parse(title)}</TitleSub>
                  <BrandTitle />
                  <ContentDefault className="pr-lg-4 mt-5" content={content} />
                </div>

                <div className="col-lg-4">
                  <CallToAction id={ctaRelation} />
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {contactRelation && (
        <section>
          <MoreInformation id={contactRelation} />
        </section>
      )}

      <div className="container pb-5">
        <Halls className="mt-5" />
      </div>
      
      {moodImage && (
        <MoodImage image={moodImage} />
      )}
    </Layout>
  )
}

export default PageTemplate